const Config = {
  datatableFeatures: {
    extraButtons: [
      // {
      //   buttonLabel: 'Download Template',
      //   buttonRef: 'downloadTemplateBtn',
      //   variant: 'info',
      // },
    ],
    buttons: {
      createButton: false,
      createButtonLabel: 'Create New Account',
      createButtonRoute: 'administration-accounts-new',
      refresh: true,
    },
    tableActionField: true,
    datepicker: true,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'name', text: 'Name' },
    { value: 'id', text: 'ID' },
    { value: 'is_active', text: 'Active?' },
  ],
  sampleData: {
    name: 'string',
    description: 'string',
    current_subscription_ends: '2022-05-18T05:04:31.990Z',
    is_active: true,
    user_id: 0,
    subscription_plan_id: 0,
    system_preference_id: 0,
    merchant_id: 'string',
    account_type: 'string',
    url: 'string',
    business_location: 'string',
    business_proof: 'string',
    account_name: 'string',
    created_at: '2022-05-18T11:13:09.662522',
    updated_at: '2022-05-18T11:13:09.662530',
  },
  formData() {
    return {
      title: {
        edit: 'Edit Account',
        view: 'View Account',
        remove: 'Remove Account',
        create: 'Create New Account',
      },
      form: [{
        formContext: 'Account',
        formName: 'account',
        elem: [
          {
            key: 'full_name',
            fieldName: 'Full Name',
            type: 'text',
            rules: 'required|alpha-spaces',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'email',
            fieldName: 'Email',
            type: 'email',
            rules: 'required|email:true',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'phone_number',
            fieldName: 'Phone Number',
            type: 'phone',
            rules: 'required',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'password',
            fieldName: 'Password',
            type: 'password',
            rules: 'required',
            show: {
              edit: false,
              remove: true,
              view: false,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            key: 'confirmPassword',
            fieldName: 'Confirm Password',
            type: 'password',
            rules: 'confirmed:password',
            show: {
              edit: false,
              remove: true,
              view: false,
              create: true,
            },
            colSize: {
              lg: 12,
            },
          },
          {
            customValidation: true,
            key: 'is_active',
            fieldName: 'Active?',
            type: 'switch',
            trueLabel: 'Active',
            falseLabel: 'Inactive',
            show: {
              edit: true,
              remove: true,
              view: true,
              create: true,
            },
            rules: '',
            colSize: {
              lg: 12,
            },
          },
        ],
      }],
    }
  },
  tableData() {
    return {
      tableAction: [
        {
          enable: true, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12', pageRoute: 'administration-accounts-details-info',
        },
        {
          enable: false, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        {
          key: 'show_details', label: '', sortable: false, visible: true, type: 'showDetails', class: 'show-details col-width',
        },
        {
          key: 'id', label: 'Id', sortable: true, visible: true, type: 'number',
        },
        {
          key: 'user_id', label: 'User Id', sortable: true, visible: false, type: 'number',
        },
        {
          key: 'subscription_plan_id', label: 'Plan Id', sortable: true, visible: true,
        },
        {
          key: 'name', label: 'Name', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'merchant_type', label: 'Merchant Type', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'description', label: 'Description', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'account_name', label: 'Account Name', sortable: true, visible: true, type: 'string',
        },
        {
          key: 'account_type', label: 'Account Type', sortable: true, visible: false, type: 'underscore_string',
        },
        {
          key: 'current_subscription_ends', label: 'Current Subscription Ends', sortable: true, visible: true, type: 'date',
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: true, type: 'date',
        },
        {
          key: 'updated_at', label: 'Updated At', sortable: true, visible: false, type: 'date', showInDetails: true,
        },
        {
          key: 'accountUrl', label: 'Url', sortable: false, visible: false, type: 'AccountUrl', showInDetails: false,
        },
        {
          key: 'business_location', label: 'Business Location', sortable: true, visible: false, type: 'string',
        },
        {
          key: 'system_preference_id', label: 'System Preference Id', sortable: false, visible: false, type: 'constant',
        },
        {
          key: 'business_proof', label: 'Business Proof', sortable: true, visible: false,
        },
        {
          key: 'record_status', label: 'Record Status', sortable: true, visible: false, showInDetails: false,
        },
        {
          key: 'is_active', label: 'Active', sortable: true, visible: true, type: 'boolFlag',
        },
        {
          key: 'actions',
          label: 'Action',
          class: 'text-right',
          visible: true,
        },
      ],
    }
  },
}

export default Config
